export default {
  siteTitle: 'Get bonus',
  blogerName: 'CANALDOGIGA',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@canaldogigazerandoslots'
    }
  ],
  projects: [
    {
      name: 'drip',
      url: 'https://dripcasino.life/c19ed0860',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c3e8fd215',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c54f74289',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c574ec27e',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/cba5a26cd',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c820b367e',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>CANALDOGIGA</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'CANALDOGIGA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
